<template>
      <!--sản phẩm 2-->
      <div class="col-12">
        <div class="col-4" style="margin-left: 40px !important;float: left;">
            <h2 class="p-2">Tin Tức</h2>
            <div>
                <img src="img/tin1.png" style="width: 100%;">
                <h3>Xả kho tết chỉ từ 99,000 cùng gearvn</h3>
            </div>
            <h2 class="p-2">Tin Tức</h2>
            <div>
                <img src="img/tin1.png" style="width: 100%;">
                <h3>Xả kho tết chỉ từ 99,000 cùng gearvn</h3>
            </div>
        </div>
        <div class="col-7 mt-2" id="app2" style="float: right; margin-right: 50px;">
            <section class="row product-list">
                <article class="col-md-4 col-sm-6 product-item" v-for="product in products2" :key="product.id">
                    <div class="card mb-4">
                        <img :src="product.image" :alt="product.name" class="card-img-top img-fluid" style="height: 200px ;">
                        <div class="card-body">
                            <h2 class="card-title" v-html="product.name"></h2>
                            <p class="card-text">Giá: {{ product.price.toLocaleString() }} VND</p>
                            <p class="card-text">Số lượng: {{ product.quantity }}</p>
                            <button @click="addToCart(product)" class="btn btn-primary">
                                Thêm vào giỏ hàng
                            </button>
                        </div>
                    </div>
                </article>
            </section>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      products2: [
        { id: 1, name: 'Bàn phím cơ RGB HOTSWAP 5', price: 10000000, quantity: 10, image: 'img/sg-11134201-22110-5hxob907t9jv8f.jfif' },
        { id: 2, name: 'Ghế gaming<br><br>', price: 10000000, quantity: 5, image: 'img/ghe-gaming-binh-duong-1-min.jpg' },
        { id: 3, name: 'Tai nghe gaming<br><br>', price: 10000000, quantity: 8, image: 'img/tai-nghe-gaming-dareu-eh416-rgb-01.png' },
        { id: 4, name: 'Acer nitro 5', price: 10000000, quantity: 7, image: 'img/m4.jfif' },
        { id: 5, name: 'Vibo X-104', price: 10000000, quantity: 3, image: 'img/m5.jpg' },
        { id: 6, name: 'HP Spectre', price: 10000000, quantity: 6, image: 'img/a1.png' },
      ]
    };
  },
  methods: {
    addToCart(product) {
      if (product.quantity > 0) {
        product.quantity--;
        alert(`Bạn đã mua ${product.name} thành công!`);
      } else {
        alert(`${product.name} đã hết hàng!`);
      }
    }
  }
};
</script>
