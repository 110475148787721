import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/Home.vue'
import CartPage from '@/components/CartPage.vue';
const routes = [
  { path: '/', component: Home },
  {path:'/CartPage', component: CartPage}
];

const router = createRouter({
  history: createWebHistory(),
  routes
});
export default router;
