import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// Nhúng file CSS
import './assets/home.css';
import './assets/acount.css';
const app = createApp(App);
app.use(router);
app.mount('#app');

