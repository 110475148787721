<template>
   <header>
        <h1>Shop Long Chính</h1>      
        <div class="wrapper"></div>
        <div id="header">
            <nav class="navbar navbar-expand-lg navbar-dark" style="padding-bottom:0px !important;">
                <div class="container">
                    <a class="navbar-brand logo" id="login-btn">
                        <img src="@/assets/img/account-icon-png-19.jpg">
                    </a>

                    <!-- Toggle button for mobile view -->
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <!-- Navbar items -->
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul id="main-menu" class="navbar-nav ms-auto">
                            <li class="nav-item">
                              <router-link to="/" class="nav-link">Trang Chủ</router-link>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" id="htmlDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Sản Phẩm</a>
                                <ul class="dropdown-menu sub-menu" aria-labelledby="htmlDropdown">
                                    <li><a class="dropdown-item" href="">Pc</a>
                                        <ul class="dropdown-menu sub-menu">
                                            <li><a class="dropdown-item" href="">Pc-Gaming</a></li>
                                            <li><a class="dropdown-item" href="">Pc-văn phòng</a></li>
                                            <li><a class="dropdown-item" href="">Pc-i7-i9</a></li>
                                            <li><a class="dropdown-item" href="">Pc-i5</a></li>
                                        </ul>
                                    </li>
                                    <li><a class="dropdown-item" href="">Laptop</a>
                                        <ul class="dropdown-menu sub-menu">
                                            <li><a class="dropdown-item" href="">Acer</a></li>
                                            <li><a class="dropdown-item" href="">Dell</a></li>
                                            <li><a class="dropdown-item" href="">Msi</a></li>
                                            <li><a class="dropdown-item" href="">Asus</a></li>
                                        </ul>
                                    </li>
                                    <li><a class="dropdown-item" href="">Phím chuột</a></li>
                                    <li><a class="dropdown-item" href="">Màn hình</a></li>
                                    <li><a class="dropdown-item" href="">khác</a></li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <router-link to="/CartPage" :key="$route.fullPath" class="nav-link">Giỏ Hàng</router-link>
                            </li>
                            <li class="nav-item">
                                <a href="" class="nav-link">Tin tức</a>
                            </li>
                            <li class="nav-item">
                                <a href="" class="nav-link">Giới thiệu</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </header>
    <div class="overlay" id="overlay"></div>
      <div class="form-container" id="authForm">
          <button class="close-btn" onclick="toggleForm()">X</button>
          <div id="loginForm">
              <h3>Đăng nhập</h3>
              <input type="text" placeholder="Tên đăng nhập" id="loginUsername">
              <input type="password" placeholder="Mật khẩu" id="loginPassword">
              <div class="forgot-password">
                  <a href="#">Quên mật khẩu?</a>
              </div>
              <button onclick="login()">Đăng nhập</button>
              <div class="switch">
                  <a href="#" onclick="switchToRegister()"><button>Đăng ký</button></a>
              </div>
          </div>
          <div id="registerForm" style="display: none;">
              <h3>Đăng ký</h3>
              <input type="text" placeholder="Tên đăng nhập" id="registerUsername">
              <input type="password" placeholder="Mật khẩu" id="registerPassword">
              <input type="password" placeholder="Nhập lại mật khẩu" id="registerConfirmPassword">
              <input type="email" placeholder="Gmail" id="registerEmail">
              <button onclick="register()">Đăng ký</button>
              <div class="switch">
                  <a href="#" onclick="switchToLogin()"><button>Đăng nhập</button></a>
              </div>
          </div>
      </div>
  </template>
  
  <script>
  export default {
    name: 'CartPage',
  };
  </script>